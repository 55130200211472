import React, { useState, useEffect, useRef } from 'react';

const ASCII_BANNER = `
  ______  __      ______  __  __       ______  __  __   __  ______  __  __  ______  __    __    
 /\\  __ \\ /\\ \\    /\\  ___\\/\\_\\_\\_\\     /\\  == \\/\\ \\/\\ "-.\\ \\/\\  ___\\/\\ \\_\\ \\/\\  __ \\/\\ "-./  \\   
 \\ \\  __ \\ \\ \\___\\ \\  __\\\\/_/\\_\\/_    \\ \\  __<\\ \\ \\ \\ \\-.  \\ \\ \\__ \\ \\  __ \\ \\  __ \\ \\ \\-./\\ \\  
  \\ \\_\\ \\_\\ \\_____\\ \\_____\\/\\_\\/\\_\\    \\ \\_____\\ \\_\\ \\_\\\\"\\_\\ \\_____\\ \\_\\ \\_\\ \\_\\ \\_\\ \\_\\ \\ \\_\\ 
   \\/_/\\/_/\\/_____/\\/_____/\\/_/\\/_/     \\/_____/\\/_/\\/_/ \\/_/\\/_____/\\/_/\\/_/\\/_/\\/_/\\/_/  \\/_/ 
`;


// Define styles object
const styles = {
  commandBody: {
    margin: 0,
    fontFamily: "'Courier New', Courier, monospace",
    backgroundColor: 'black',
    color: '#a1ff82',
    overflow: 'clip',
    position: 'relative',
    minHeight: '100vh',
    minWidth: '100%',
  },
  commandPrompt: {
    padding: '20px',
    overflowY: 'auto',
    cursor: 'text',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
  banner: {
    whiteSpace: 'pre',
    color: '#a1ff82',
    fontSize: '0.8em',
    lineHeight: 1.2,
    fontFamily: 'monospace',
    position: 'sticky',
    top: '0',
    marginBottom: '20px',
    animation: 'scroll 8s linear infinite',
    translate: '-50%',
    zIndex: 10,
    width: '100%',
  },
  command: {
    whiteSpace: 'pre',
    color: '#a1ff82',
  },
  computerCommand: {
    whiteSpace: 'pre',
    color: 'white',
  },
  inputLine: {
    display: 'flex',
  },
  inputWrapper: {
    position: 'relative',
    flex: 1,
  },
  prompt: {
    marginRight: '5px',
    color: '#a1ff82',
  },
  input: {
    flex: 1,
    width: '100%',
    background: 'transparent',
    color: '#a1ff82',
    border: 'none',
    outline: 'none',
    fontFamily: "'Courier New', Courier, monospace",
    fontSize: 'inherit',
  },
  blockCursor: {
    content: '""',
    position: 'absolute',
    width: '0.6em',
    height: '1.2em',
    backgroundColor: '#a1ff82',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
    opacity: 0.7,
    animation: 'blink 1s step-end infinite',
  }
};

const Command = () => {
  const [command, setCommand] = useState('');
  const [commandsHistory, setCommandsHistory] = useState([
    { user: 'computer', message: 'Hello, do you have a secret word?' }
  ]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);
  const historyRef = useRef(null);

  const getResponse = () => {
    const computerResponses = [
      "I can't help you with that",
      "I don't understand your request",
      "Could you please rephrase that?",
      "That's beyond my capabilities",
      "I'm not sure what you mean",
      "Could you be more specific?",
      "I'm afraid I can't do that",
      "That's an interesting request, but I can't fulfill it",
    ];
    return computerResponses[Math.floor(Math.random() * computerResponses.length)];
  };

  const scrollToBottom = () => {
    if (historyRef.current) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
  };

  const handleInputChange = (e) => {
    setCommand(e.target.value);
    setCursorPosition(e.target.selectionStart || 0);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (command.trim()) {
        setCommandsHistory(prev => [...prev, { user: 'user', message: command }]);
        setCommand('');
        setCursorPosition(0);

        setTimeout(() => {
          setCommandsHistory(prev => [...prev, {
            user: 'computer',
            message: getResponse()
          }]);
          scrollToBottom();
        }, 1000);
      }
    }
  };

  const handleKeyDown = (e) => {
    requestAnimationFrame(() => {
      setCursorPosition(inputRef.current?.selectionStart || 0);
    });
  };

  const handleClick = (e) => {
    setCursorPosition(inputRef.current?.selectionStart || 0);
  };

  useEffect(() => {
    inputRef.current?.focus();
    scrollToBottom();
  }, [commandsHistory]);

  return (
    <div style={styles.commandBody} onClick={() => inputRef.current?.focus()}>
      <style>
        {`
          @keyframes blink {
            0%, 50% { opacity: 0.7; }
            51%, 100% { opacity: 0; }
          }
          @keyframes scroll {
            0% { color: pink; transform: translateX(50%)}
            25% { color: #f56342; transform: translateX(105%)}
            50% { color: #f542e3; transform: translateX(50%)}
            75% { color: #7FFF00; transform: translateX(105%)}
            100% { transform: translateX(50%); color: #42f2f5; }
          }
        `}
      </style>

      <div style={styles.banner}>
        {ASCII_BANNER}
      </div>
      
      <div style={styles.commandPrompt} ref={historyRef}>
        <div>
          {commandsHistory.map((cmd, index) => (
            <div 
              key={index} 
              style={cmd.user === "computer" ? styles.computerCommand : styles.command}
            >
              &gt; {cmd.message}
            </div>
          ))}
        </div>
        
        <div style={styles.inputLine}>
          <span style={styles.prompt}>&gt;</span>
          <div style={styles.inputWrapper}>
            <input
              ref={inputRef}
              type="text"
              value={command}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              onClick={handleClick}
              style={styles.input}
              spellCheck="false"
              autoComplete="off"
              autoFocus
            />
            <div 
              style={{
                ...styles.blockCursor,
                left: `${cursorPosition * 0.6}em`,
                opacity: document.activeElement === inputRef.current ? 1 : 0,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Command;