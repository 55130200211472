import Command from './command';

function App() {
  return (
    <main style={{width: '100%', padding: 0, margin: 0, overflow: 'hidden'}}>
    <Command />
    </main>
  );
}

export default App;
